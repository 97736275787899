<template>
  <el-dialog
    title="Настройки критериев"
    :visible.sync="show"
    width="50%"
    modal-append-to-body
    append-to-body
    :close-on-click-modal="false"
    @close="close"
  >

    <component
      :is="scalesScopesTemplate"
      :scale="element.scale"
      :disabled="disabled"
      @add-scope="saveScope({})"
      @save-scope="saveScope"
      @delete-scope="deleteScope"
    ></component>

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Закрыть</el-button>
    </div>

  </el-dialog>
</template>

<script>


import requestSender from "@/api/base/requestSender";

export default {
  name: "card-element-configurable-scale-edit-dialog",
  components: {},
  props: {
    disabled: {type: Boolean, default: false},
  },

  computed: {

    scalesScopesTemplate() {
      return this.$companyConfiguration.scales.getConfigurableScaleSettingsTemplate(this.element?.scale?.template?.type);
    },

  },
  data() {
    return {
      show: false,
      loading: false,

      element: {},

    }
  },
  mounted() {

  },
  methods: {
    open(element) {
      this.element = element;
      this.show = true;
    },
    close() {
      this.loading = false;
      this.show = false;
      if( !this.disabled ){
        this.$emit('close');
      }
    },


    saveScope(scope){
      this.loading = true;
      requestSender('post', 'scale/save-scale-scope', {
        scale_id: this.element.scale.id,
        scope_id: scope.id,
        data: scope,
      })
        .then(data => {
          this.$set(this.element.scale, 'scopes', data.scale.scopes);
        })
        .finally(()=>{
          this.loading = false;
        })
    },
    deleteScope(scope){
      this.loading = true;
      requestSender('post', 'scale/delete-scale-scope', {
        scale_id: this.element.scale.id,
        scope_id: scope.id,
      })
        .then(data => {
          this.$set(this.element.scale, 'scopes', data.scale.scopes);
        })
        .finally(()=>{
          this.loading = false;
        })
    },

  },
}
</script>

<style>

</style>